<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="基本信息">
        <el-descriptions :column="2" border>
          <!-- <el-descriptions-item
            label="用户头像"
            :span="3"
            :labelStyle="{ 'vertical-align': 'top' }"
          >
            <Picture :attachId="basicInfo.avatar" width="240px" />
          </el-descriptions-item> -->
          <el-descriptions-item label="用户ID">{{
              basicInfo.userId
            }}
          </el-descriptions-item>
          <el-descriptions-item label="注册时间">{{
              basicInfo.registTime
            }}
          </el-descriptions-item>
          <el-descriptions-item label="注册来源">{{
              basicInfo.registSource
            }}
          </el-descriptions-item>
          <el-descriptions-item label="手机号码">
            {{ basicInfo.userPhone }}
          </el-descriptions-item>
          <!-- <el-descriptions-item label="昵称">{{
            basicInfo.nickName
          }}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{
            basicInfo.userName
          }}</el-descriptions-item>
          <el-descriptions-item label="证件号码">{{
            basicInfo.idCardNo
          }}</el-descriptions-item>
          <el-descriptions-item label="使用终端">{{
            basicInfo.terminal
          }}</el-descriptions-item> -->
          <el-descriptions-item label="绑定支付">{{
              basicInfo.bindingPay
            }}
          </el-descriptions-item>
          <el-descriptions-item label="钱包余额（元）">
            <span v-if="basicInfo.walletBalance !== ''">{{ (parseFloat(basicInfo.walletBalance)).toFixed(2) }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="支付宝免密">{{
              basicInfo.zfbFreePay
            }}
          </el-descriptions-item>
          <el-descriptions-item label="剩余积分">{{
              basicInfo.surplusScore
            }}
          </el-descriptions-item>
          <el-descriptions-item label="云闪付免密">{{
              basicInfo.cloudFreePay
            }}
          </el-descriptions-item>
          <!-- <el-descriptions-item label="认证时间">{{
            basicInfo.authenticationTime
          }}</el-descriptions-item> -->
          <el-descriptions-item label="认证状态">{{
              authorityStatus[basicInfo.authorityStatus]
            }}
          </el-descriptions-item>
          <!-- <el-descriptions-item label="APP隐私协议版本">{{
            basicInfo.appPrivacyAgreementVersion
          }}</el-descriptions-item> -->
        </el-descriptions>
      </easy-card>
      <easy-card title="车辆信息">
        <el-table
            :data="vehicleList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column
              label="车牌号码"
              prop="autoPlateNumber"
              width="240px"
          >
            <template slot-scope="scope">
              <Numplate :type="scope.row.autoColor">{{
                  scope.row.autoPlateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column label="绑定时间" prop="bindingTime" width="400px"/>
          <el-table-column label="车牌颜色" prop="autoColor" width="160px">
            <template slot-scope="scope">{{
                autoColorConfig[scope.row.autoColor]
              }}
            </template>
          </el-table-column>
          <el-table-column label="车辆类型" prop="autoType" width="160px"/>
          <el-table-column label="车辆品牌" prop="autoBrand"/>
          <el-table-column label="发动机型号" prop="engineModel"/>
          <el-table-column label="车辆余额" prop="autoBalance" width="240px"/>
        </el-table>
      </easy-card>
      <easy-card title="长租信息">
        <el-table
            :data="leaseList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="长租单号" prop="leaseNo" width="300px"/>
          <el-table-column
              label="车牌号码"
              prop="autoPlateNumber"
              width="120px"
          >
            <template slot-scope="scope">
              <Numplate :type="scope.row.autoColor">{{
                  scope.row.autoPlateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column label="泊位号" prop="parkingspace" width="160px"/>
          <el-table-column label="车场" prop="parkingLotName" width="240px"/>
          <el-table-column label="区域" prop="regionName"/>
          <el-table-column label="地址" prop="address" width="300px"/>
          <el-table-column label="开始时间" prop="beginTime" width="240px"/>
          <el-table-column label="结束时间" prop="endTime" width="240px"/>
          <el-table-column
              label="订单状态"
              prop="leaseOrderStatus"
              width="240px"
          />
          <el-table-column label="长租金额" prop="leaseAmount" width="240px"/>
        </el-table>
      </easy-card>
      <easy-card title="用户充值记录">
        <el-table
            :data="rechargeList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column align="center" label="充值信息">
            <el-table-column label="充值单号" prop="orderNo" width="240">
            </el-table-column>
            <el-table-column
                label="车牌号码"
                prop="autoPlateNumber"
                width="120"
            >
              <template slot-scope="scope">
                <Numplate :type="scope.row.autoColor">{{
                    scope.row.autoPlateNumber
                  }}
                </Numplate>
              </template>
            </el-table-column>
            <el-table-column label="充值金额" prop="rechargeAmount" width="120">
            </el-table-column>
            <el-table-column label="时间" prop="rechargeTime" width="240px">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="渠道信息">
            <el-table-column label="来源方式" prop="sourceMethod" width="160">
            </el-table-column>
            <el-table-column label="充值对象" prop="rechargeTarget" width="160">
            </el-table-column>
            <el-table-column
                label="巡检员姓名"
                prop="inspectorName"
                width="160"
            >
            </el-table-column>
            <el-table-column label="渠道流水" prop="channelFlow" width="300px">
            </el-table-column>
          </el-table-column>
          <el-table-column
              align="center"
              label="来源端"
              prop="source"
              width="100px"
          ></el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="userRechargeListPagination.pageNo"
              :page-size="userRechargeListPagination.pageSize"
              :total="userRechargeListPagination.totalCount"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleRechargeSizeChange"
              @current-change="handleRechargeCurrentChange"
          >
          </el-pagination>
        </div>
      </easy-card>
      <!--停车订单-->
      <easy-card title="停车订单">
        <div slot="extra">
          <el-button
              size="small"
              type="primary"
              @click="showUserParkingOrderAll"
          >全部
          </el-button
          >
          <el-button
              size="small"
              type="primary"
              @click="showUserArrearsParkingOrderList"
          >欠费记录
          </el-button
          >
        </div>
        <el-table
            :data="parkingOrderList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column align="center" label="停车信息">
            <el-table-column label="订单号" prop="orderNo" width="180">
            </el-table-column>
            <el-table-column label="城区" prop="regionName" width="120">
            </el-table-column>
            <el-table-column label="车场" prop="parkingLotName" width="200">
            </el-table-column>
            <el-table-column label="泊位号" prop="parkingspace" width="180px">
            </el-table-column>
            <el-table-column label="车牌号" width="120">
              <template slot-scope="scope">
                <Numplate :type="scope.row.autoColor">{{
                    scope.row.autoPlateNumber
                  }}
                </Numplate>
              </template>
            </el-table-column>
            <el-table-column label="驶入时间" prop="driveInTime" width="240">
            </el-table-column>
            <el-table-column label="驶出时间" prop="driveOutTime" width="240">
            </el-table-column>
            <el-table-column label="停车时长" prop="parkingTime" width="120px">
            </el-table-column>
            <el-table-column label="停车图片" prop="" width="160px">
              <template slot-scope="scope">
                <el-button
                    class="common-text"
                    size="small"
                    type="text"
                    @click="showParkingPicture(scope.row)"
                >查看停车图片
                </el-button
                >
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="交易信息">
            <el-table-column
                label="订单金额（元）"
                prop="orderAmount"
                width="120px"
            >
            </el-table-column>
            <el-table-column
                label="冻结金额（元）"
                prop="freezeAmount"
                width="120px"
            >
            </el-table-column>
            <el-table-column
                label="交易金额（元）"
                prop="tradeAmount"
                width="120px"
            >
            </el-table-column>
            <el-table-column
                label="优惠金额（元）"
                prop="couponAmount"
                width="120px"
            >
            </el-table-column>
            <el-table-column label="抵扣额" prop="deduction" width="160">
            </el-table-column>
            <el-table-column
                label="线下减免（元）"
                prop="offLineDeduction"
                width="160"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
              align="center"
              label="付款状态"
              width="100px"
          ></el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="parkingOrderPagination.pageNo"
              :page-size="parkingOrderPagination.pageSize"
              :total="parkingOrderPagination.totalCount"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleParkingOrderSizeChange"
              @current-change="handleParkingOrderCurrentChange"
          >
          </el-pagination>
        </div>
      </easy-card>
      <easy-card title="缴费记录">
        <div slot="extra">
          <el-button
              size="small"
              type="primary"
              @click="getUserPaymentRecords('0')"
          >全部
          </el-button
          >
          <el-button
              size="small"
              type="primary"
              @click="getUserPaymentRecords('1')"
          >支付宝
          </el-button
          >
          <el-button
              size="small"
              type="primary"
              @click="getUserPaymentRecords('2')"
          >微信
          </el-button
          >
          <el-button
              size="small"
              type="primary"
              @click="getUserPaymentRecords('3')"
          >巡检代缴
          </el-button
          >
        </div>
        <el-table
            :data="payList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="缴费单号" prop="payNo" width="240px"/>
          <el-table-column
              label="车牌号码"
              prop="autoPlateNumber"
              width="120px"
          >
            <template slot-scope="scope">
              <Numplate :type="scope.row.autoColor">{{
                  scope.row.autoPlateNumber
                }}
              </Numplate>
            </template>
          </el-table-column>
          <el-table-column label="缴费金额" prop="payAmount" width="160px"/>
          <el-table-column label="巡检员" prop="inspectorName" width="160px"/>
          <el-table-column label="缴费时间" prop="payTime"/>
          <el-table-column label="渠道用户" prop="channelUser"/>
          <el-table-column
              label="渠道流水号"
              prop="channelFlowNo"
              width="240px"
          />
        </el-table>
      </easy-card>
      <easy-card title="优惠券信息">
        <el-table
            :data="couponList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column align="center" label="优惠信息">
            <el-table-column label="优惠券编号" prop="couponNo" width="160">
            </el-table-column>
            <el-table-column label="触发事件" prop="eventName" width="120">
            </el-table-column>
            <el-table-column label="优惠券类型" prop="couponType" width="120">
              <template slot-scope="scope">
                <span>{{ couponType[scope.row.couponType] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="优惠额度" prop="couponAmount" width="120px">
            </el-table-column>
            <el-table-column
                label="有效日期始"
                prop="effectiveDateStart"
                width="160"
            >
            </el-table-column>
            <el-table-column
                label="有效日期止"
                prop="effectiveDateEnd"
                width="160"
            >
            </el-table-column>
            <el-table-column label="结算方" prop="settlementName" width="120">
            </el-table-column>
            <el-table-column label="使用限制" prop="useLimit" width="240px">
            </el-table-column>
            <el-table-column label="发行编号" prop="publishNo" width="240px">
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="使用信息">
            <el-table-column label="使用时间" prop="usedTime" width="160">
            </el-table-column>
            <el-table-column label="使用区域" prop="region" width="160">
            </el-table-column>
            <el-table-column label="使用车场" prop="parkingLotName" width="160">
            </el-table-column>
            <el-table-column label="订单编号" prop="orderNo" width="300px">
            </el-table-column>
            <el-table-column
                label="订单金额"
                prop="parkingOrderAmount"
                width="160"
            >
            </el-table-column>
            <el-table-column
                label="订单类型"
                prop="parkingOrderType"
                width="300px"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
      </easy-card>
      <el-dialog
          :visible.sync="parkingPictureVisible"
          append-to-body
          class="common-dialog"
          title="停车图片"
      >
        <div class="parking-picture-wrap">
          <div class="parking-picture-item">
            <p>{{ currentRow.autoPlateNumber }}</p>
            <el-carousel height="200px">
              <el-carousel-item
                  v-for="(item, index) in currentRow.driveInParkingPhoto"
                  :key="index"
              >
                <Picture :attachId="item"/>
              </el-carousel-item>
            </el-carousel>
            <p class="parking-time">{{ currentRow.driveInTime }}</p>
          </div>
          <div class="parking-picture-item">
            <p>{{ currentRow.autoPlateNumber }}</p>
            <el-carousel height="200px">
              <el-carousel-item
                  v-for="(item, index) in currentRow.driveOutParkingPhoto"
                  :key="index"
              >
                <Picture :attachId="item"/>
              </el-carousel-item>
            </el-carousel>
            <p class="parking-time">{{ currentRow.driveOutTime }}</p>
          </div>
        </div>
        <el-descriptions :column="2" border size="small">
          <el-descriptions-item label="车牌号码">
            <Numplate :type="currentRow.autoColor">{{
                currentRow.autoPlateNumber
              }}
            </Numplate>
          </el-descriptions-item>
          <el-descriptions-item label="停车时长">{{
              currentRow.parkingTime
            }}
          </el-descriptions-item>
          <el-descriptions-item label="订单金额（元）">{{
              currentRow.orderAmount
            }}
          </el-descriptions-item>
          <el-descriptions-item label="实收（元）">{{
              currentRow.tradeAmount
            }}
          </el-descriptions-item>
          <el-descriptions-item :span="3" label="是否付款">{{
              currentRow.payStatus
            }}
          </el-descriptions-item>
        </el-descriptions>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getBasicUserInformation,
  getUserCouponList,
  getUserLeaseList,
  getUserParkingOrderList,
  getUserPaymentRecords,
  getUserRechargeList,
  getUserVehicleList,
} from "@/api/user";
import Numplate from "@/components/Numplate";
import Picture from "@/components/Picture";

export default {
  name: "user-detail",
  components: {
    Numplate,
    Picture,
  },
  data() {
    return {
      userId: "",
      authorityStatus: {
        "0": "未通过",
        "1": "未认证",
        "2": "待签约",
        "3": "已认证",
      },
      autoColorConfig: {
        "01": "黄底黑字",
        "02": "蓝底白字",
        "03": "绿色",
        "04": "黄绿",
        "06": "黑底白字",
        23: "白底黑字",
        99: "其他",
      },
      basicInfo: {},
      vehicleList: [],
      leaseList: [],
      rechargeList: [],
      parkingOrderList: [],
      parkingOrderPagination: {
        pageNo: 1,
        pageSize: 10,
        totalPages: 1,
        totalCount: 0,
        isArrears: "0",
      },
      payList: [],
      couponList: [],
      couponType: {
        0: "面额券",
        1: "时长券",
        2: "免费停车",
      },
      userRechargeListPagination: {
        pageNo: 1,
        pageSize: 10,
        totalPages: 1,
        totalCount: 0,
      },
      parkingPictureVisible: false,
      currentRow: {},
    };
  },
  methods: {
    showParkingPicture(row) {
      this.parkingPictureVisible = true;
      this.currentRow = row;
    },
    // parkingPhotoFormat(str) {
    //   return str === "" ? [] : JSON.parse(str);
    // },
    // 获取用户基本信息
    async getUserBasicInfo() {
      const res = await getBasicUserInformation({
        id: this.userId,
      });
      if (res && res.code === 30 && res.result) {
        this.basicInfo = res.returnObject;
      }
    },
    // 获取用户车辆信息
    async getUserVehicleList() {
      const res = await getUserVehicleList({
        id: this.userId,
      });
      if (res && res.code === 30 && res.result) {
        this.vehicleList = res.returnObject;
      }
    },
    // 获取用户长租记录
    async getUserLeaseList() {
      const res = await getUserLeaseList({
        id: this.userId,
      });
      if (res && res.code === 30 && res.result) {
        this.leaseList = res.returnObject;
      }
    },
    async getUserRechargeList() {
      const res = await getUserRechargeList({
        id: this.userId,
        pageNo: this.userRechargeListPagination.pageNo,
        pageSize: this.userRechargeListPagination.pageSize,
      });
      if (res && res.code === 30 && res.result) {
        this.rechargeList = res.returnObject.list;
        this.userRechargeListPagination.totalPages = res.returnObject.totalPage;
        this.userRechargeListPagination.totalCount = res.returnObject.count;
      }
    },
    handleRechargeSizeChange(e) {
      this.userRechargeListPagination.pageSize = e;
      this.getUserRechargeList();
    },
    handleRechargeCurrentChange(e) {
      this.userRechargeListPagination.pageNo = e;
      this.getUserRechargeList();
    },
    // 获取用户停车订单列表
    async getUserParkingOrderList() {
      const res = await getUserParkingOrderList({
        id: this.userId,
        pageNo: this.parkingOrderPagination.pageNo,
        pageSize: this.parkingOrderPagination.pageSize,
        isArrears: this.parkingOrderPagination.isArrears,
      });

      if (res && res.code === 30 && res.result) {
        this.parkingOrderList = res.returnObject.list;
        this.parkingOrderPagination.totalPages = res.returnObject.totalPage;
        this.parkingOrderPagination.totalCount = res.returnObject.count;
      }
    },
    showUserParkingOrderAll() {
      this.parkingOrderPagination.isArrears = "0";
      this.getUserParkingOrderList();
    },
    showUserArrearsParkingOrderList() {
      this.parkingOrderPagination.isArrears = "1";
      this.getUserParkingOrderList();
    },
    handleParkingOrderSizeChange(e) {
      this.parkingOrderPagination.pageSize = e;
      this.getUserParkingOrderList();
    },
    handleParkingOrderCurrentChange(e) {
      this.parkingOrderPagination.pageNo = e;
      this.getUserParkingOrderList();
    },
    /**
     * 获取用户缴费记录
     */
    async getUserPaymentRecords(payMethod) {
      const res = await getUserPaymentRecords({
        id: this.userId,
        payMethod: payMethod,
      });
      if (res && res.code === 30 && res.result) {
        this.payList = res.returnObject;
      }
    },
    async getUserCouponList() {
      const res = await getUserCouponList({
        id: this.userId,
      });
      if (res && res.code === 30 && res.result) {
        this.couponList = res.returnObject;
      }
    },
  },
  mounted() {
    this.userId = this.$route.query.id;
    // 获取用户基本信息
    this.getUserBasicInfo();
    this.getUserVehicleList();
    this.getUserLeaseList();
    this.getUserRechargeList();
    this.showUserParkingOrderAll();
    // 获取用户缴费记录
    this.getUserPaymentRecords("0");
    this.getUserCouponList();
  },
};
</script>

<style lang="less" scoped>
.avatar {
  width: 200px;
  height: 200px;
  background: red;
}

.labelStyle {
  vertical-align: top !important;
}

.parking-picture-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;

  .parking-picture-item {
    border: 1px solid #ddd;
    min-height: 200px;
    flex: 1;
    margin: 10px;
    padding: 5px;

    p {
      text-align: center;
      padding: 5px;

      &.parking-time {
        background: #f2f2f2;
        padding: 7px;
      }
    }
  }
}
</style>
